<template>
  <div>
    <div v-if="!showRecord">
      <Header title="会员信息详情" @back="onBack"></Header>
      <h3 class="ui-title">会员信息</h3>
      <a-descriptions style="margin-top: 30px">
        <a-descriptions-item label="会员手机号">{{
          memberInfo.phone
        }}</a-descriptions-item>
        <a-descriptions-item label="昵称">{{
          memberInfo.nickname ? memberInfo.nickname : "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="真实姓名">{{
          memberInfo.realName
        }}</a-descriptions-item>
        <a-descriptions-item label="性别">{{
          transSex(memberInfo.sex)
        }}</a-descriptions-item>
        <a-descriptions-item label="生日">{{
          transDateTime(memberInfo.birthday, 1)
        }}</a-descriptions-item>
        <a-descriptions-item label="地区"
          >{{ memberInfo.province }} {{ memberInfo.city }}
          {{ memberInfo.area }}</a-descriptions-item
        >
        <a-descriptions-item label="分销等级">{{
          memberInfo.distributionTitle || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="账号状态">{{
          memberInfo.isDisabled == 0 ? "正常" : "冻结"
        }}</a-descriptions-item>
        <!-- <a-descriptions-item label="是否绑定微信">{{memberInfo.wxNo ? '已绑定' : '未绑定'}} <span @click="onBind" class="ui-bind">{{memberInfo.wxNo ? '解绑' : '去绑定'}}</span></a-descriptions-item> -->
        <!-- <a-descriptions-item label="是否关注">{{memberInfo.isSubscription == 1 ? '已关注' : '未关注'}}</a-descriptions-item> -->
        <a-descriptions-item label="是否添加企微客服">{{
          memberInfo.addService == 1 ? "已添加" : "未添加"
        }}</a-descriptions-item>
      </a-descriptions>

      <h3 class="ui-title">
        <span style="margin-right: 20px">会员标签</span>
        <span v-permission="['member_manage_info_label']">
          <labelSelect v-model:value="labelIds" @change="onLabelChange">
            <a-button type="primary">手动打标签</a-button>
          </labelSelect>
        </span>
      </h3>
      <div class="ui-card">
        <div v-if="labelList.length">
          <a-tag
            class="ui-label"
            color="blue"
            v-for="item in labelList"
            :key="item.tagId"
            >{{ item.tagTitle }}
            <Icon icon="CloseOutlined" @click="onLabelClose(item)"></Icon>
          </a-tag>
        </div>
        <div v-else>
          <a-empty></a-empty>
        </div>
      </div>

      <a-row style="margin-bottom: 20px" v-if="memberInfo.id">
        <a-button @click="onRefresh" type="primary" style="margin-right: 10px"
          >刷新</a-button
        >
        <a-button
          v-permission="['member_manage_info_disabled']"
          @click="onFrozen"
          v-show="memberInfo.isDisabled == 0"
          type="primary"
          style="margin-right: 10px"
          >冻结</a-button
        >
        <a-button
          v-permission="['member_manage_info_enabled']"
          @click="onFrozen"
          v-show="memberInfo.isDisabled != 0"
          type="primary"
          style="margin-right: 10px"
          >解冻</a-button
        >
        <!--            <a-button v-permission="['member_manage_info_bind_exchange']" v-show="currentTab == 1 && showCoupon" @click="onBindCoupon" type="primary" style="margin-right: 10px">绑定兑换券</a-button>-->
        <a-button
          v-permission="['member_manage_info_bind_preferential']"
          v-show="showCoupon && currentTab == 9"
          @click="onBindCoupon"
          type="primary"
          >绑定兑换券</a-button
        >
        <a-button
          v-permission="['member_manage_info_bind_snack']"
          v-show="showCoupon && currentTab == 10"
          @click="onBindCoupon"
          type="primary"
          style="margin-right: 10px"
          >绑定卖品券</a-button
        >
        <a-button
          v-permission="['member_manage_info_bind_numberCard']"
          v-show="currentTab == 2 && showCoupon"
          type="primary"
          style="margin-right: 10px"
          @click="onBindCard(1)"
          >绑定次卡</a-button
        >
        <a-button
          v-permission="['member_manage_info_bind_yearCard']"
          v-show="currentTab == 3 && showCoupon"
          type="primary"
          style="margin-right: 10px"
          @click="onBindCard(2)"
          >绑定年卡</a-button
        >
        <a-button
          v-permission="['member_manage_info_bind_stored']"
          v-show="currentTab == 13 && showCoupon"
          type="primary"
          style="margin-right: 10px"
          @click="onBindCard(3)"
          >绑定线上会员卡</a-button
        >
        <a-button
          v-permission="['member_manage_info_bind_memberCard']"
          v-show="currentTab == 14 && showCoupon"
          type="primary"
          style="margin-right: 10px"
          @click="onBindCard(4)"
          >绑定影城会员卡</a-button
        >
      </a-row>

        <a-tabs type="card" @change="onTabs" >
            <a-tab-pane v-for="t in tableTabs" :tab="t.title" :key="t.key"></a-tab-pane>
        </a-tabs>
        <div>
            <a-spin :spinning="loading" size="large">
                <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: ['1','2','3','10', '13'].indexOf(currentTab) !== -1 ? 1300 : 2000 }" tableLayout="auto">
                    <template #bodyCell="{ column, record, index }">

                        <template v-if="column.key == 'couponBindTime'">
                            <div v-if="record.createTime">{{transDateTime(record.createTime)}}</div>
                            <div v-else>--</div>
                        </template>

						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-else> 抖音小程序 </a-tag>
							</div>
						</template>

              <template v-if="column.key == 'couponValidTime'">
                <div v-if="record.startTime">
                  <div>{{ transDateTime(record.startTime, 1) }}</div>
                  <div>至</div>
                  <div>{{ transDateTime(record.endTime, 1) }}</div>
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'showTime'">
                <div v-if="record.showStartTime">
                  <div>{{ transDateTime(record.showStartTime) }}</div>
                  <div>至</div>
                  <div>{{ transDateTime(record.showEndTime) }}</div>
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'couponStatus'">
                <div>{{ transCouponStatus(record.status) }}</div>
              </template>

              <template v-if="column.key == 'cardStatus'">
                <div>{{ transCardStatus(record.status) }}</div>
              </template>

              <template v-if="column.key == 'cardTitle'">
                <div>{{ record.cardTitle || "--" }}</div>
              </template>

              <template v-if="column.key == 'validTime'">
                <div v-if="record.isNeverExpire == 1">永久有效</div>
                <div v-else>
                  <div v-if="record.expireDate">
                    {{ transDateTime(record.expireDate, 1) }}
                  </div>
                  <div v-else>--</div>
                </div>
              </template>

              <template v-if="column.key == 'bindTime'">
                <div v-if="record.createTime">
                  {{ transDateTime(record.createTime) }}
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'cinemaInfo'">
                <div v-if="record.orderStoreVO">
                  {{ record.orderStoreVO.name }}
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'orderInfo'">
                <div>订单编号：{{ record.orderNo || "--" }}</div>
                <div>支付编号：{{ record.outTradeNo || "--" }}</div>
                <div v-if="currentTab == 5">
                  物流编号：{{ record.logisticsNo || "--" }}
                </div>
              </template>

              <template v-if="column.key == 'ticketCode'">
                <div v-if="record.ticketVO">{{ record.ticketVO.code }}</div>
                <div v-else>--</div>
              </template>

                        <template v-if="column.key == 'couponType'">
                            <div v-if="record.couponType">
                                <a-tag style="font-size: 12px;" :color="['pink', 'orange', 'green', 'purple', 'cyan', 'blue', 'red'][record.couponType - 1]">
                                    {{ ['兑换券(旧)', '年卡', '次卡', '兑换券', '小卖券','线上会员卡', '抖音券', '影城会员卡'][record.couponType - 1] }}支付
                                </a-tag>
                                <div style="margin-top: 6px;" v-if="(record.couponType === 2 || record.couponType === 3 || record.couponType === 6) && record.cardNumber">卡号：<span style="color: #888;">{{ record.cardNumber }}</span></div>
                                <div style="margin-top: 6px;" v-if="(record.couponType === 1 || record.couponType === 5) && record?.barcodes?.length">券号：
                                    <div style="color: #888;" v-for="(item, i) in record.barcodes" :key="item">
                                        {{ item }}
                                        <span v-if="i < record.barcodes.length - 1">、</span>
                                    </div>
                                </div>
                                <div v-if="record.payType === 7">
                                  <div v-if="record.tikTokTicketOrderInfoVOS?.length">
                                    <div style="border: solid 1px #eee;border-radius: 8px;padding: 6px 10px;"
                                      v-for="item in record.tikTokTicketOrderInfoVOS" :key="item.barcode">
                                      <p style="padding: 0;margin: 0;">抖音券号：{{ item.barcode || '-' }}</p>
                                      <p style="padding: 0;margin: 0;">抖音券名称：{{ item.title || '-' }}</p>
                                      <p style="padding: 0;margin: 0;">抖音商品sku：{{ item.goodsSku || '-' }}</p>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div style="color:orange">{{['兑换券','年卡','次卡'][record.couponType - 1]}}</div> -->
                                <!-- <div>{{record.couponCode}}</div> -->
                            </div>
                            <div style="color:purple" v-else>
                                <a-tag color="purple">现金支付</a-tag>
                            </div>
                        </template>

              <template v-if="column.key == 'filmInfo'">
                <div v-if="record.ticketVO">
                  <div>{{ record.ticketVO.shortName || "--" }}</div>
                  <div>{{ record.ticketVO.hallName || "--" }}</div>
                  <div>{{ transDateTime(record.ticketVO.showDateTime) }}</div>
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'refundTicketInfo'">
                <div>{{ transRefundTicketStatus(record.status) }}</div>
              </template>

              <template v-if="column.key == 'ticketInfo'">
                <div v-if="record.status !== 'AFTERSALE_FINISH'">
                  <div>{{ transTicketStatus(record.ticketStatus) }}</div>
                  <div v-if="record.printTime">
                    {{ transDateTime(record.printTime) }}
                  </div>
                </div>
                <div v-else>
                  <div>已退票</div>
                  <!--										<div>退票时间：</div>-->
                </div>
              </template>

              <template v-if="column.key === 'isDisabled'">
                {{ record.isDisabled ? "已禁用" : "已启用" }}
              </template>

              <template v-if="column.key == 'ticketCreateTime'">
                <div>{{ transDateTime(record.createTime) }}</div>
              </template>

              <template v-if="column.key == 'goodsPayTime'">
                <div>{{ transDateTime(record.payTime) }}</div>
              </template>

              <template v-if="column.key == 'goodsInfo'">
                <div v-if="currentTab != 8">
                  <div v-for="goodsItem in record.goodsInfoVOS">
                    <a-image
                      v-if="goodsItem.imgUrl"
                      :width="50"
                      :src="goodsItem.goodsImgUrl"
                    />
                    <div>{{ goodsItem.mainTitle || "--" }}</div>
                    <!-- <div class="ui-price">￥{{item.payment || 0}}</div> -->
                  </div>
                </div>
                <div v-if="currentTab == 8">
                  <div
                    class="ui-goods"
                    v-for="item in record.goodsInfoVOS"
                    :key="item.id"
                  >
                    <div style="display: flex; align-items: flex-start">
                      <a-image
                        style="width: 50px; border-radius: 4px"
                        :src="item.imgUrl"
                      ></a-image>
                      <div style="margin-left: 8px">
                        <div style="font-size: 12px">
                          <a-tag
                            v-if="item.isCard"
                            style="padding: 2px; line-height: 12px"
                            color="cyan"
                            size="small"
                            >次卡</a-tag
                          >
                          {{ item.mainTitle }}
                        </div>
                        <div
                          style="
                            margin-top: 2px;
                            font-size: 14px;
                            color: orange;
                          "
                        >
                          ￥{{ item.nowPrice }}
                          <span style="margin-left: 10px; color: #999"
                            >x{{ item.buyNum }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="item.isCard"
                      style="margin-top: 4px; font-size: 12px"
                    >
                      <div>卡等级: {{ item.cardVolumeVO.title }}</div>
                      <div v-if="item.cardNumberList">
                        卡号:
                        <span
                          v-for="(subItem, subIndex) in item.cardNumberList"
                        >
                          {{ subItem }}
                          <span v-if="subIndex < item.cardNumberList.length - 1"
                            >、</span
                          >
                        </span>
                      </div>
                      <div>观影次数: {{ item.cardBalanceTimes }}</div>
                    </div>
                    <div
                      v-if="item.isCoupon"
                      style="margin-top: 4px; font-size: 12px"
                    >
                      <div
                        style="margin-bottom: 5px"
                        v-if="item.ticketBatchNum"
                      >
                        <div>
                          兑换券批次号:
                          {{
                            item.ticketCouponList.length
                              ? item.ticketCouponList[0].batchNo
                              : ""
                          }}
                        </div>
                        <div>
                          兑换券数量: {{ item.ticketBatchNum }}张 x{{
                            item.buyNum
                          }}
                        </div>
                        <div>
                          兑换券号：
                          <a-tag
                            v-for="(item, i) in item.ticketCouponList"
                            :key="i"
                          >
                            {{ item.barcode }}
                          </a-tag>
                        </div>
                      </div>
                      <div v-if="item.snackBatchNum">
                        <div>
                          卖品券批次号:
                          {{
                            item.snackCouponList.length
                              ? item.snackCouponList[0].batchNo
                              : ""
                          }}
                        </div>
                        <div>
                          卖品券数量: {{ item.snackBatchNum }}张 x{{
                            item.buyNum
                          }}
                        </div>
                        <div>
                          卖品券号:
                          <a-tag
                            v-for="(item, i) in item.snackCouponList"
                            :key="i"
                          >
                            {{ item.barcode }}
                          </a-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="column.key == 'cardInfo'">
                <div v-for="goodsItem in record.goodsInfoVOS">
                  <div v-if="goodsItem.isCard">
                    <a-tag v-if="goodsItem.cardType == 1" color="orange"
                      >次卡</a-tag
                    >
                    <a-tag v-if="goodsItem.cardType == 2" color="blue"
                      >年卡</a-tag
                    >
                    <div>{{ record.couponCode }}</div>
                    <div v-if="goodsItem.cardType == 1">
                      开卡次数：{{ goodsItem.cardBalanceTimes || 0 }}
                    </div>
                  </div>
                  <div v-if="goodsItem.isRechargeCard">
                    <a-divider style="margin: 6px 0" v-if="goodsItem.isCard" />
                    <a-tag color="orange">线上会员卡</a-tag>
                    <div>初始金额：{{ goodsItem.initAmount || 0 }}元</div>
                  </div>
                  <div v-if="!goodsItem.isCard && !goodsItem.isRechargeCard">
                    --
                  </div>
                </div>
              </template>

              <template v-if="column.key == 'storeInfo'">
                <div v-if="record.orderStoreVO">
                  {{ record.orderStoreVO.name }}
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'transactionInfo'">
                <div>交易单号：{{ record.outTradeNo || "--" }}</div>
                <div>订单编号：{{ record.orderNo || "--" }}</div>
                <div>
                  支付状态：<span style="color: green">{{
                    transOrderStatus(record.status)
                  }}</span>
                </div>
                <div>
                  交易总额：<span style="color: orange"
                    >￥{{ record.payment || "--" }}</span
                  >
                </div>
                <div v-if="record.orderAfterSale">
                  <div>退款信息：{{ record.orderAfterSale.description }}</div>
                  <div>退款金额：{{ record.orderAfterSale.refund }}</div>
                </div>
                <div v-else>退款信息：无</div>
              </template>

              <template v-if="column.key == 'deliveryType'">
                <div>
                  {{ ["无", "自提", "快递"][record.deliveryType - 1] }}
                  <span v-if="record.deliveryType == 3"
                    >：{{ record.deliveryFee }}元</span
                  >
                </div>
                <div v-if="record.deliveryType == 2">
                  <div id="copys">{{ record.verificationCode }}</div>
                  <a
                    v-if="record.verificationCode"
                    class="ui-copy__btn"
                    @click="onCopy(index)"
                    >复制核销码</a
                  >
                </div>
              </template>

              <template v-if="column.key == 'sendGoodsInfo'">
                <div v-if="record.deliveryType == 2">
                  <div>核销状态：{{ transOrderStatus(record.status) }}</div>
                  <div>核销人员：{{ record.verificationUserName || "--" }}</div>
                  <div>核销时间：{{ transDateTime(record.finishTime) }}</div>
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'addrInfo'">
                <div v-if="record.deliveryType == 3">
                  <div v-if="record.orderAddress">
                    <div>收货人：{{ record.orderAddress.name }}</div>
                    <div>手机号：{{ record.orderAddress.phone }}</div>
                    <div>地址：{{ record.orderAddress.address }}</div>
                  </div>
                </div>
                <div v-else-if="record.deliveryType == 2">
                  <div v-if="record.orderStoreVO">
                    <div>自提商城：{{ record.orderStoreVO.name }}</div>
                    <div>地址：{{ record.orderStoreVO.address }}</div>
                  </div>
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key == 'buyerInfo'">
                <div>会员昵称：{{ record.nickname || "--" }}</div>
                <div>会员手机号：{{ record.phone }}</div>
              </template>

              <template v-if="column.key == 'distributionGoodsInfo'">
                <div v-for="orderItem in record.oiList">
                  <a-image
                    v-if="orderItem.picUrl"
                    :width="40"
                    :src="orderItem.goodsImgUrl"
                  />
                  <div>{{ orderItem.mainTitle }}</div>
                </div>
              </template>

              <template v-if="column.key == 'priceInfo'">
                <div v-if="record.oiList && record.oiList.length">
                  <div>销售价：{{ record.oiList[0].price || 0 }}元</div>
                  <div>市场价：{{ record.oiList[0].originPrice || 0 }}元</div>
                  <div>成本价：{{ record.oiList[0].costPrice || 0 }}元</div>
                </div>
              </template>

              <template v-if="column.key == 'distributionGoodsNum'">
                <div v-for="orderItem in record.oiList">
                  {{ orderItem.num || 0 }}
                </div>
              </template>

              <template v-if="column.key == 'paymentInfo'">
                <div>商品总额：{{ record.totalFee || 0 }}元</div>
                <div>快递：{{ record.deliveryFee || 0 }}元</div>
                <div>实付金额：{{ record.payment || 0 }}元</div>
              </template>

              <template v-if="column.key == 'distributionOrderInfo'">
                <div>订单编号：{{ record.orderNo || "--" }}</div>
                <div>支付编号：{{ record.outTradeNo || "--" }}</div>
                <div>下单时间：{{ transDateTime(record.createTime) }}</div>
              </template>

              <template v-if="column.key == 'returnMoneyInfo'">
                <div v-for="recordItem in record.commissionRecord">
                  <div v-if="recordItem.level != 4">
                    <span v-if="recordItem.level == 1" style="color: purple"
                      >自购返佣</span
                    >
                    <span
                      v-else-if="recordItem.level == 2"
                      style="color: #5e7e2d"
                      >邀请人返佣</span
                    >
                    <span v-else-if="recordItem.level == 3" style="color: blue"
                      >团队长分佣</span
                    >
                    <!-- <div v-else-if="recordItem.level == 4" style="color:green;">分账</div> -->
                    <span>：{{ recordItem.commission || 0 }}元</span>
                  </div>
                </div>
              </template>

              <template v-if="column.key == 'returnMoneyTime'">
                <div v-for="recordItem in record.commissionRecord">
                  <div
                    v-if="recordItem.level != 4"
                    :style="{
                      color: ['purple', '#5e7e2d', 'blue'][
                        recordItem.level - 1
                      ],
                    }"
                  >
                    {{ transDateTime(recordItem.createTime) }}
                  </div>
                </div>
              </template>

              <template v-if="column.key == 'payInfo'">
                <div>订单编号：{{ record.orderNo || "--" }}</div>
                <div>支付编号：{{ record.outTradeNo || "--" }}</div>
                <div>
                  支付状态：<span style="color: green">{{
                    transOrderStatus(record.status)
                  }}</span>
                </div>
                <div>
                  交易总额：<span style="color: orange"
                    >￥{{ record.payment || "--" }}</span
                  >
                </div>
                <div>
                  下单时间：<span style="color: orange">{{
                    transDateTime(record.createTime)
                  }}</span>
                </div>
              </template>

              <template v-if="column.key == 'afterSaleMark'">
                <div v-if="record.orderAfterSale">
                  <div style="color: red">已退款</div>
                  <div>退款金额：{{ record.orderAfterSale.refund }}</div>
                  <div>退款备注：{{ record.orderAfterSale.description }}</div>
                  <div>
                    退款时间：{{
                      transDateTime(record.orderAfterSale.createTime)
                    }}
                  </div>
                </div>
                <div v-else>--</div>
              </template>

              <template v-if="column.key === 'action'">
                <a-dropdown
                  v-if="
                    (currentTab == 2 || currentTab == 3 || currentTab == 13) &&
                    record.status != 7
                  "
                  :trigger="['click', 'hover']"
                >
                  <a-button @click.prevent
                    >操作 <Icon icon="DownOutlined"></Icon>
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <div
                        v-if="record.isDisabled == 0 && record.status != 2"
                        @click="onDisabled(record)"
                        v-permission="['member_manage_info_disabled_card']"
                      >
                        <a-menu-item> 禁用 </a-menu-item>
                      </div>

                      <div
                        v-if="record.isDisabled == 1 && record.status != 2"
                        @click="onDisabled(record)"
                        v-permission="['member_manage_info_enabled_card']"
                      >
                        <a-menu-item> 启用 </a-menu-item>
                      </div>

                      <div
                        v-if="record.status == 1 && currentTab != 13"
                        @click="onRefund(record)"
                        v-permission="['member_manage_info_refund_card']"
                      >
                        <a-menu-item> 退卡 </a-menu-item>
                      </div>

                      <div
                        v-if="record.isNeverExpire != 1 && currentTab != 13"
                        @click="onDelay(record)"
                        v-permission="['member_manage_info_delay']"
                      >
                        <a-menu-item> 延期 </a-menu-item>
                      </div>

                      <div
                        v-if="currentTab == 13"
                        @click="onModifyBalance(record)"
                        v-permission="['member_manage_info_modify']"
                      >
                        <a-menu-item> 修改余额 </a-menu-item>
                      </div>
                    </a-menu>
                  </template>
                </a-dropdown>

                <a-dropdown
                  v-if="
                    (currentTab == 1 || currentTab == 9 || currentTab == 10) &&
                    (record.status == 1 ||
                      record.status == 3 ||
                      record.status == 4)
                  "
                  :trigger="['click', 'hover']"
                >
                  <a-button @click.prevent
                    >操作 <Icon icon="DownOutlined"></Icon>
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <div
                        @click="onDelay(record)"
                        v-permission="['member_manage_info_delay']"
                      >
                        <a-menu-item> 延期 </a-menu-item>
                      </div>

                      <div
                        @click="onUnBind(record)"
                        v-if="!record.isGrant"
                        v-permission="['member_manage_info_unbind_exchange']"
                      >
                        <a-menu-item> 解绑 </a-menu-item>
                      </div>
                    </a-menu>
                  </template>
                </a-dropdown>

                <a-dropdown
                  v-if="currentTab == 14"
                  :trigger="['click', 'hover']"
                >
                  <a-button @click.prevent
                    >操作 <Icon icon="DownOutlined"></Icon>
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <div
                        v-if="currentTab == 14"
                        @click="onMemberCardDetails(record)"
                        v-permission="['member_manage_info_card_detail']"
                      >
                        <a-menu-item>影城会员卡详情</a-menu-item>
                      </div>
                    </a-menu>
                  </template>
                </a-dropdown>
              </template>
            </template>
          </a-table>
        </a-spin>
      </div>

      <a-modal
        title="修改线上会员卡余额"
        width="600px"
        v-model:visible="showModifyModal"
        @cancel="onModifyCancel"
        @ok="onModifyOk"
      >
        <a-spin :spinning="loading">
          <a-form
            ref="modifyForm"
            name="modifyForm"
            :model="modifyModelRef"
            :labelCol="{ span: 6 }"
            :wrapperCol="{ span: 14 }"
          >
            <a-form-item label="现有余额">
              {{ modifyItem.balance || 0 }} 元
            </a-form-item>

            <a-form-item
              label="充值金额"
              name="balance"
              :rules="[{ required: true, message: '必填项不允许为空' }]"
            >
              <a-input-number
                v-model:value="modifyModelRef.balance"
                :min="'-' + modifyItem.balance"
                :precision="2"
                placeholder="请输入"
              >
              </a-input-number>
              元
            </a-form-item>

            <a-form-item label="充值后金额">
              {{
                (modifyItem.balance + (modifyModelRef.balance || 0)).toFixed(2)
              }}
              元
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <a-modal
        v-model:visible="visibleBind"
        title="绑定微信号"
        @ok="handleBindOk"
      >
        <span style="color: #ff0000">*</span>
        <span>微信号：</span>
        <a-input
          style="width: 380px"
          v-model:value="wxNo"
          placeholder="请输入微信号"
        ></a-input>
      </a-modal>

      <a-modal
        v-model:visible="visibleBindCoupon"
        :title="
          currentTab == 1
            ? '绑定兑换券'
            : currentTab == 10
            ? '绑定卖品券'
            : '绑定兑换券'
        "
        @cancel="handleBindCouponCancel"
        @ok="handleBindCouponOk"
      >
        <a-form ref="cardFormRef" :model="cardModel">
          <div v-if="currentTab == 1">
            <a-form-item
              class="ui-form__item"
              label="影院组织"
              name="organizationId"
              :rules="[{ required: true, message: '必填项不允许为空' }]"
            >
              <a-select
                placeholder="请选择影院组织"
                v-model:value="cardModel.organizationId"
                style="width: 180px"
                @change="getAllCinemaList"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :vlaue="item.id"
                  :key="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item
              class="ui-form__item"
              label="所属影院"
              name="cinemaId"
              :rules="[{ required: true, message: '必填项不允许为空' }]"
            >
              <a-select
                placeholder="请选择所属影院"
                v-model:value="cardModel.cinemaId"
                style="width: 180px"
              >
                <a-select-option
                  v-for="item in cinemaAllList"
                  :vlaue="item.id"
                  :key="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>

          <a-form-item
            name="couponNo"
            :label="
              currentTab == 1
                ? '兑换券号'
                : currentTab == 10
                ? '卖品券号'
                : '兑换券号'
            "
            :rules="[{ required: true, message: '必填项不允许为空' }]"
          >
            <a-input
              style="width: 380px"
              v-model:value="cardModel.couponNo"
              placeholder="请输入券号进行绑定"
            ></a-input>
          </a-form-item>
        </a-form>
      </a-modal>

      <a-modal
        v-model:visible="visibleReason"
        title="冻结用户"
        @ok="handleReasonOk"
      >
        <span style="color: #ff0000">*</span>
        <span>原因：</span>
        <a-input
          style="width: 380px"
          v-model:value="frozenReason"
          placeholder="请输入原因"
        ></a-input>
      </a-modal>

      <a-modal
        v-model:visible="delayVisible"
        destroyOnClose
        width="800px"
        :title="currentDelayTitle"
        @ok="onDelayOk"
        @cancel="onDelayCancel"
      >
        <a-spin :spinning="loading">
          <a-form
            ref="delayForm"
            name="delayForm"
            :model="delayModelRef"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 16 }"
          >
            <div v-if="!visible">
              <a-form-item label="会员手机号">{{
                setRecordData.memberPhone
              }}</a-form-item>
              <a-form-item
                :label="currentTab == 2 || currentTab == 3 ? '卡号' : '券号'"
                >{{ setRecordData.cardNumber }}</a-form-item
              >
              <a-form-item label="有效时间">{{
                transDateTime(setRecordData.expireDate, 1)
              }}</a-form-item>
            </div>

            <a-form-item
              label="快捷选择"
              name="setValue"
              :rules="[{ required: true, message: '必选项不允许为空' }]"
            >
              <a-radio-group
                v-model:value="delayModelRef.setValue"
                button-style="solid"
              >
                <a-radio-button :value="1">1天</a-radio-button>
                <a-radio-button :value="2">7天</a-radio-button>
                <a-radio-button :value="3">3月</a-radio-button>
                <a-radio-button :value="4">6月</a-radio-button>
                <a-radio-button :value="5">1年</a-radio-button>
                <a-radio-button :value="6">自定义日期</a-radio-button>
                <a-radio-button :value="7">自定义时间</a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item
              v-if="delayModelRef.setValue === 6"
              label="延期至"
              name="specifiesTime"
              :rules="[{ required: true, message: '必选项不允许为空' }]"
            >
              <a-date-picker
                :disabled-date="disabledDate"
                v-model:value="delayModelRef.specifiesTime"
              />
              <p style="margin-top: 6px; color: #666">需大于今天才会生效</p>
            </a-form-item>
            <a-form-item
              v-else-if="delayModelRef.setValue === 7"
              label="延期至"
              name="delayDays"
              :rules="[{ required: true, message: '必选项不允许为空' }]"
            >
              <a-input-number
                v-model:value="delayModelRef.delayDays"
                :min="0"
                :precision="0"
                placeholder="请输入"
              ></a-input-number>
              <span style="padding-left: 8px">天</span>
            </a-form-item>
            <a-form-item v-else-if="!visible" label="延期至">
              <span>{{
                transDateTime(delayEndTime(setRecordData.expireDate), 1)
              }}</span>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <a-modal
        v-model:visible="refundVisible"
        title="退卡"
        @cancel="onRefundCancel"
        @ok="onRefundOk"
      >
        <a-spin :spinning="loading">
          <a-form ref="refundForm" name="refundForm" :model="refundData">
            <a-form-item
              label="退卡备注"
              name="remark"
              :rules="[{ required: true, message: '请退卡的备注和金额' }]"
            >
              <a-textarea
                v-model:value="refundData.remark"
                placeholder="请输入退卡的备注和金额"
              ></a-textarea>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <a-modal
        v-model:visible="bindCardVisible"
        width="750px"
        :title="
          bindModel.type === 1
            ? '绑定次卡'
            : bindModel.type === 2
            ? '绑定年卡'
            : bindModel.type === 3
            ? '绑定线上会员卡'
            : '绑定影城会员卡'
        "
        @cancel="onBindCancel"
        @ok="onBindCardOk"
      >
        <a-spin :spinning="loading">
          <a-form
            ref="bindForm"
            name="bindForm"
            :model="bindModel"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 16 }"
          >
            <a-form-item
              name="organizationId"
              label="影院组织"
              :rules="[{ required: true, message: '必选项不允许为空' }]"
            >
              <a-select
                placeholder="请选择影院组织"
                v-model:value="bindModel.organizationId"
                @change="getAllCinemaList"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :vlaue="item.id"
                  :key="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item
              name="cinemaId"
              label="所属影院"
              :rules="[{ required: true, message: '必选项不允许为空' }]"
            >
              <a-select
                placeholder="请选择所属影院"
                v-model:value="bindModel.cinemaId"
                @change="cinemaChange"
              >
                <a-select-option
                  v-for="item in cinemaAllList"
                  :vlaue="item.id"
                  :key="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item
              v-if="bindModel.type !== 4"
              label="卡等级"
              name="cardId"
              :rules="[{ required: true, message: '必选项不允许为空' }]"
            >
              <a-select
                style="width: 300px"
                v-model:value="bindModel.cardId"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in cardPolicyList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.title }}</a-select-option
                >
              </a-select>
              <a-button
                style="margin-left: 10px"
                type="primary"
                shape="circle"
                @click="getCardPolicyList"
              >
                <template #icon>
                  <Icon icon="RedoOutlined"></Icon>
                </template>
              </a-button>
            </a-form-item>

            <a-form-item
              v-if="bindModel.type === 1 || bindModel.type === 2"
              label="开卡日期"
              name="createTime"
              :rules="[{ required: true, message: '必选项不允许为空' }]"
            >
              <a-date-picker
                v-model:value="bindModel.createTime"
                :disabledDate="disabledCreateTime"
              />
            </a-form-item>

            <div v-if="bindModel.type === 1">
              <a-form-item
                label="次卡卡号"
                name="cardNumber"
                :rules="[
                  { required: true, message: '必选项不允许为空' },
                  {
                    pattern: /^[0-9]*[1-9][0-9]*$/,
                    message: '卡号不符合规则，请重新输入',
                  },
                ]"
              >
                <a-input
                  type="number"
                  v-model:value="bindModel.cardNumber"
                  placeholder="请输入次卡卡号"
                ></a-input>
              </a-form-item>

              <a-form-item
                label="次卡开卡金额"
                name="orderMoney"
                :rules="[{ required: true, message: '必选项不允许为空' }]"
              >
                <a-input-number
                  v-model:value="bindModel.orderMoney"
                  placeholder="请输入"
                  :min="0"
                  :precision="2"
                ></a-input-number>
              </a-form-item>

              <a-form-item
                label="次卡总次数"
                name="cardBalance"
                :rules="[{ required: true, message: '必选项不允许为空' }]"
              >
                <a-input-number
                  v-model:value="bindModel.cardBalance"
                  placeholder="请输入"
                  :min="0"
                  :precision="0"
                ></a-input-number>
              </a-form-item>

              <a-form-item
                label="次卡剩余次数"
                name="usableTotal"
                :rules="[{ required: true, message: '必选项不允许为空' }]"
              >
                <a-input-number
                  v-model:value="bindModel.usableTotal"
                  placeholder="请输入"
                  :min="0"
                  :precision="0"
                ></a-input-number>
              </a-form-item>
            </div>

            <div v-if="bindModel.type === 2">
              <!-- <a-form-item label="年卡类型" name="cardType">
                            <a-radio-group v-model:value="bindModel.cardType" button-style="solid">
                                <a-radio-button type="primary" :value="1">普通年卡</a-radio-button>
                            </a-radio-group>
                        </a-form-item> -->
              <a-form-item
                label="年卡卡号"
                name="cardNumber"
                :rules="[
                  { required: true, message: '必选项不允许为空' },
                  {
                    pattern: /^[0-9]*[1-9][0-9]*$/,
                    message: '卡号不符合规则，请重新输入',
                  },
                ]"
              >
                <a-input
                  type="number"
                  v-model:value="bindModel.cardNumber"
                  placeholder="请输入年卡卡号"
                ></a-input>
              </a-form-item>

              <a-form-item
                label="年卡金额"
                name="orderMoney"
                :rules="[{ required: true, message: '必选项不允许为空' }]"
              >
                <a-input-number
                  v-model:value="bindModel.orderMoney"
                  placeholder="请输入"
                  :min="0"
                  :precision="2"
                ></a-input-number>
                元
              </a-form-item>
            </div>

            <div v-if="bindModel.type === 3">
              <a-form-item
                label="线上会员卡卡号"
                name="cardNumber"
                :rules="[
                  { required: true, message: '必选项不允许为空' },
                  {
                    pattern: /^[0-9]*[1-9][0-9]*$/,
                    message: '卡号不符合规则，请重新输入',
                  },
                ]"
              >
                <a-input
                  type="number"
                  v-model:value="bindModel.cardNumber"
                  placeholder="请输入线上会员卡卡号"
                ></a-input>
              </a-form-item>

              <a-form-item
                label="余额"
                name="balance"
                :rules="[{ required: true, message: '必选项不允许为空' }]"
              >
                <a-input-number
                  v-model:value="bindModel.balance"
                  placeholder="请输入"
                  :min="0"
                  :precision="2"
                ></a-input-number>
                元
              </a-form-item>

              <a-form-item label="是否启用" name="isDisabled">
                <a-radio-group v-model:value="bindModel.isDisabled">
                  <a-radio :value="0">是</a-radio>
                  <a-radio :value="1">否</a-radio>
                </a-radio-group>
              </a-form-item>
            </div>

            <div v-if="bindModel.type === 4">
              <a-form-item
                label="会员手机号"
                name="phone"
                :rules="[{ required: true, message: '必选项不允许为空' }]"
              >
                <a-input
                  v-model:value="bindModel.phone"
                  placeholder="请输入会员手机号"
                >
                </a-input>
              </a-form-item>

              <a-form-item
                label="影城会员卡号"
                name="cardNumber"
                :rules="[
                  { required: true, message: '必选项不允许为空' },
                  {
                    pattern: /^[0-9]*[1-9][0-9]*$/,
                    message: '卡号不符合规则，请重新输入',
                  },
                ]"
              >
                <a-input
                  type="number"
                  v-model:value="bindModel.cardNumber"
                  placeholder="请输入影城会员卡号"
                ></a-input>
              </a-form-item>
              <a-form-item
                label="影城会员卡密"
                name="password"
                :rules="[{ required: true, message: '必填项不允许为空' }]"
              >
                <a-input-password
                  v-model:value="bindModel.password"
                  placeholder="请输入影城会员卡密"
                />
              </a-form-item>
            </div>

            <a-form-item
              v-if="bindModel.type === 1 || bindModel.type === 2"
              label="是否永久有效"
              name="isNeverExpire"
              :rules="[{ required: true, message: '必填项不允许为空' }]"
            >
              <a-radio-group v-model:value="bindModel.isNeverExpire">
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item
              v-if="bindModel.isNeverExpire === 0"
              label="过期时间"
              name="expireDate"
              :rules="[{ required: true, message: '必填项不允许为空' }]"
            >
              <a-date-picker v-model:value="bindModel.expireDate" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </div>

    <Recharge
      v-if="showRecord"
      :cardInfo="memberCardInfo"
      @back="goBack"
    ></Recharge>
  </div>
</template>

<script>
	import Header from '@/components/header/header.vue';
    import { Icon } from '@/components/icon/icon.js';
    import Recharge from "@/views/coupon/memberCard/info/recharge.vue";
    import labelSelect from '@/components/labelSelect/index.vue';
	import { updateUserTag } from '@/service/modules/label.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import {getMemberDetail,getCardList,getCouponList,disabledUser,enabledUser,getOrderList,getCommissionList,bindExchange,
		bindNumberCard,
		bindYearCard,
		bindRechargeCard,
		bindMemberCard
	} from '@/service/modules/member.js';
    import { getCardPolicyList, delayCardInfo,delayExchange,cancelCard,pullOffShelvesCardInfo,pullOnShelvesCardInfo,unbundlingExchangeInfo, modifyBalance} from '@/service/modules/coupon.js';
	export default {
		components: {Header,Icon, labelSelect, Recharge},
		props: {
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
			if(this.id) {
				this.getMemberDetail();
                this.getData();
			}
		},
		data() {
			return {
                tableTabs: [
                    // {title: "影城兑换券(旧)", key: "1", id: "tab-one"},
					{ title: "会员卡", key: "14", id: "tab-fourteen" },
					{title: "影城线上会员卡", key: "13", id: "tab-thirteen"},
					{title: "影城兑换券", key: "9", id: "tab-nine"},
					{title: '卖品券', key: "10", id: "tab-ten"},
                    {title: "次数卡", key: "2", id: "tab-two"},
                    {title: "年卡", key: "3", id: "tab-three"},
                    {title: "购票记录", key: "4", id: "tab-four"},
                    {title: "商品记录", key: "5", id: "tab-five"},
                    {title: "卖品记录", key: "8", id: "tab-eight"},
                    {title: "商品返佣", key: "6", id: "tab-six"},
					{title: "影票返佣", key: "11", id: "tab-eleven"},
					{title: "卖品返佣", key: "12", id: "tab-twelve"}
                    // {title: "自购返佣", key: "7", id: "tab-seven"},
                ],
				labelIds: [],
				showRecord: false,
                memberCardInfo: {},
				labelList: [],
                pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
                columns: [{
                        title: '影院名称',
                        dataIndex: 'cinemaName'
                    }, {
                        title: '券号',
                        dataIndex: 'barcode'
                    }, {
                        title: '绑定时间',
                        key: 'couponBindTime'
                    }, {
                        title: '有效期',
                        key: 'couponValidTime'
                    }, {
                        title: '兑换券状态',
                        key: 'couponStatus'
                    }, {
                        title: '操作',
                        key: 'action',
                        width: 100,
                        fixed: 'right'
                    }],
                list: [],
                visibleBind: false,
                visibleBindCoupon: false,
                visibleReason: false,
                loading: false,
                memberInfo: {},
                showCoupon: true,
                wxNo: '',
                couponNo: '',
                frozenReason: '',
                currentTab: 1,
                delayVisible: false,
                setRecordData: {},
				delayModelRef: {},
                setValue: undefined,
				visible: false,
                refundVisible: false,
				refundData: {},
				bindCardVisible: false,
				organizationList: [],
				cinemaAllList: [],
				cardPolicyList: [],
				bindModel: {
					type: 1, // 1次卡  2年卡
				},
				cardModel:{},
				showModifyModal: false,
				modifyItem: {},
				modifyModelRef: {},
				currentDelayTitle: ''
			}
		},
		methods: {
            goBack() {
                this.showRecord = false;
            },
			onBack() {
				this.$emit('back');
			},
			async getMemberDetail() {
                this.loading = true;
                try {
                    let {data} = await getMemberDetail({ id: this.id });
                    this.loading = false;
                    this.memberInfo = data;
					this.labelList = data.tagList || [];
					this.labelIds = this.labelList.map(item => {return Number(item.tagId)});
                } catch (error) {
                    this.loading = false;
                }
            },
            getData(){
                if(this.currentTab == 1 || this.currentTab == 9 || this.currentTab == 10){
                    this.showCoupon = true;
                    this.getCouponList();
                }
                if(this.currentTab == 2 || this.currentTab == 3 || this.currentTab == 13 || this.currentTab == 14){
					this.showCoupon = true;
                    this.getCardList();
                }
                if(this.currentTab == 4 || this.currentTab == 5 || this.currentTab == 8){
                    this.getOrderList();
                }
                if(this.currentTab == 6 || this.currentTab == 7 || this.currentTab == 11 || this.currentTab == 12){
                    this.getCommissionList();
                }
            },
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId, isRef) {
				if (!isRef) {
					this.cinemaAllList = [];
					this.modelRef.cinemaId = undefined;
					this.cinemaChange();
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getCardPolicyList() {
				if (!this.bindModel.cinemaId) {
					return this.$message.warn('请选择所属影院后重试');
				}
				this.cardLoading = true;
				try {
					let ret = await getCardPolicyList({
						page: 1,
						pageSize: 99999,
						organizationId: this.bindModel.organizationId,
						useCinemaId: this.bindModel.cinemaId,
						type: this.bindModel.type,
						isDisabled: 0
					});
					this.cardLoading = false;
					if (ret.code === 200) {
						this.cardPolicyList = ret.data.list;
					}
				} catch(e) {
					this.cardLoading = false;
				}
			},
			cinemaChange() {
				this.cardPolicyList = [];
				this.bindModel.cardId = undefined;
				this.getCardPolicyList();
			},
			onBindCancel() {
				this.$refs.bindForm.resetFields();
				this.organizationList = [];
				this.cinemaAllList = [];
				this.cardPolicyList = [];
				this.bindCardVisible = false;
			},
			onOpenWin() {
				window.open('https://lbs.qq.com/getPoint/');
			},
            // 切换标签页
            onTabs(key) {
                this.currentTab = key;
				this.$nextTick(() => {
					this.showCoupon = false;
					this.pagination.current = 1;
					this.list = [];
					this.getData();
					this.columns = this.setColomn(key);
				})
            },
            setColomn(index){
                const colomn = {
                    1:[{
                        title: '影院名称',
                        dataIndex: 'cinemaName'
                    }, {
                        title: '兑换券号',
                        dataIndex: 'barcode'
                    }, {
                        title: '绑定时间',
                        key: 'couponBindTime'
                    }, {
                        title: '有效期',
                        key: 'couponValidTime'
                    }, {
                        title: '兑换券状态',
                        key: 'couponStatus'
                    }, {
                        title: '操作',
                        key: 'action',
                        width: 100,
                        fixed: 'right'
                    }],
                    2:[{
                        title: '影院名称',
                        dataIndex: 'cinemaName'
                    }, {
                        title: '卡号',
                        dataIndex: 'cardNumber'
                    }, {
                        title: '开卡金额',
                        dataIndex: 'orderMoney'
                    }, {
                        title: '开卡次数',
                        dataIndex: 'cardBalance'
                    }, {
                        title: '卡等级',
                        key: 'cardTitle',
                    }, {
                        title: '有效期',
                        key: 'validTime'
                    }, {
                        title: '绑定时间',
                        key: 'bindTime'
                    }, {
                        title: '状态',
                        key: 'cardStatus'
                    }, {
                        title: '操作',
                        key: 'action',
                        width: 100,
                        fixed: 'right'
                    }],
                    3:[{
                        title: '影院名称',
                        dataIndex: 'cinemaName'
                    }, {
                        title: '卡号',
                        dataIndex: 'cardNumber'
                    }, {
                        title: '开卡金额',
                        dataIndex: 'orderMoney'
                    }, {
                        title: '购票次数',
                        dataIndex: 'usedCount'
                    }, {
                        title: '卡等级',
                        key: 'cardTitle',
                    }, {
                        title: '有效期',
                        key: 'validTime'
                    }, {
                        title: '绑定时间',
                        key: 'bindTime'
                    }, {
                        title: '状态',
                        key: 'cardStatus'
                    }, {
                        title: '操作',
                        key: 'action',
                        width: 100,
                        fixed: 'right'
                    }],
                    4:[{
                        title: '影院名称',
                        key: 'cinemaInfo'
                    }, {
                        title: '订单信息',
                        key: 'orderInfo'
                    }, {
						title: '订单来源',
						key: 'source',
						width: 120,
						align: 'center'
					}, {
                        title: '取票号',
                        dataIndex: 'ticketNum'
                        // key: 'ticketCode'
                    }, {
                        title: '票数',
                        dataIndex: 'num'
                    }, {
                        title: '座位价格',
                        dataIndex: 'price'
                    }, {
                        title: '支付金额',
                        dataIndex: 'payment'
                    }, {
                        title: '支付类型',
                        key: 'couponType'
                    }, {
                        title: '影片信息',
                        key: 'filmInfo'
                    }, {
                        title: '交易状态',
                        key: 'refundTicketInfo'
                    }, {
                        title: '取票信息',
                        key: 'ticketInfo'
                    }, {
                        title: '下单时间',
                        key: 'ticketCreateTime'
                    }],
                    5:[{
                        title: '商城名称',
                        key: 'storeInfo'
                    }, {
						title: '订单来源',
						key: 'source',
						width: 120,
						align: 'center'
					}, {
                        title: '交易信息',
                        key: 'transactionInfo'
                    }, {
                        title: '商品信息',
                        key: 'goodsInfo',
                        width: '20%'
                    }, {
                        title: '卡信息',
                        key: 'cardInfo'
                    }, {
                        title: '配送方式',
                        key: 'deliveryType',
                        align: 'center'
                    }, {
                        title: '发货/核销信息',
                        key: 'sendGoodsInfo',
                    }, {
                        title: '收货信息',
                        key: 'addrInfo'
                    }, {
                        title: '交易时间',
                        key: 'goodsPayTime'
                    }],
                    6:[{
                        title: '买家信息',
                        key: 'buyerInfo'
                    }, {
                        title: '商品信息',
                        key: 'distributionGoodsInfo',
                        width: '20%'
                    }, {
                        title: '商品单价',
                        key: 'priceInfo'
                    }, {
                        title: '数量',
                        key: 'distributionGoodsNum'
                    }, {
                        title: '订单交易总额',
                        key: 'paymentInfo'
                    }, {
                        title: '订单信息',
                        key: 'distributionOrderInfo'
                    }, {
                        title: '返佣信息',
                        key: 'returnMoneyInfo',
						width: 180
                    }, {
                        title: '返佣发放时间',
                        key: 'returnMoneyTime'
                    }],
                    7:[{
                        title: '买家信息',
                        key: 'buyerInfo'
                    }, {
                        title: '商品信息',
                        key: 'distributionGoodsInfo',
                        width: '20%'
                    }, {
                        title: '商品单价',
                        key: 'priceInfo'
                    }, {
                        title: '数量',
                        key: 'distributionGoodsNum'
                    }, {
                        title: '订单交易总额',
                        key: 'paymentInfo'
                    }, {
                        title: '订单信息',
                        key: 'distributionOrderInfo'
                    }, {
                        title: '返佣信息',
                        key: 'returnMoneyInfo'
                    }, {
                        title: '返佣发放时间',
                        key: 'returnMoneyTime'
                    }],
                    8:[{
                        title: '影院名称',
                        key: 'storeInfo'
                    }, {
						title: '订单来源',
						key: 'source',
						width: 120,
						align: 'center'
					}, {
                        title: '支付信息',
                        key: 'payInfo'
                    },
					{
                        title: '卖品信息',
                        key: 'goodsInfo',
                        width: '20%'
                    },
					{
                        title: '核销信息',
                        key: 'sendGoodsInfo',
                    }, {
                        title: '售后退款备注',
                        key: 'afterSaleMark'
                    }],
					11: [{
                        title: '买家信息',
                        key: 'buyerInfo'
                    }, {
						title: '影片信息',
						dataIndex: 'filmName'
					}, {
						title: '放映时间',
						key: 'showTime'
					}, {
						title: '支付金额',
						dataIndex: 'payment'
					}, {
						title: '订单信息',
						key: 'distributionOrderInfo'
					}, {
						title: '取票信息',
						key: 'ticketInfo'
					},  {
                        title: '返佣信息',
                        key: 'returnMoneyInfo'
                    }, {
                        title: '返佣发放时间',
                        key: 'returnMoneyTime'
                    }],
					10: [{
						title: '影院名称',
						dataIndex: 'cinemaName'
					}, {
						title: '卖品券号',
						dataIndex: 'barcode'
					}, {
                        title: '绑定时间',
                        key: 'couponBindTime'
                    }, {
                        title: '有效期',
                        key: 'couponValidTime'
                    }, {
                        title: '卖品券状态',
                        key: 'couponStatus'
                    }, {
                        title: '操作',
                        key: 'action',
                        width: 100,
                        fixed: 'right'
                    }],
					12: [{
                        title: '买家信息',
                        key: 'buyerInfo'
                    }, {
                        title: '卖品信息',
                        key: 'distributionGoodsInfo',
                        width: '20%'
                    }, {
                        title: '商品单价',
                        key: 'priceInfo'
                    }, {
                        title: '数量',
                        key: 'distributionGoodsNum'
                    }, {
                        title: '订单交易总额',
                        key: 'paymentInfo'
                    }, {
                        title: '订单信息',
                        key: 'distributionOrderInfo'
                    }, {
                        title: '返佣信息',
                        key: 'returnMoneyInfo',
						width: 180
                    }, {
                        title: '返佣发放时间',
                        key: 'returnMoneyTime'
                    }],
					9:[{
						title: '影院名称',
						dataIndex: 'cinemaName'
					}, {
						title: '兑换券号',
						dataIndex: 'barcode'
					}, {
                        title: '绑定时间',
                        key: 'couponBindTime'
                    }, {
                        title: '有效期',
                        key: 'couponValidTime'
                    }, {
                        title: '兑换券状态',
                        key: 'couponStatus'
                    }, {
                        title: '操作',
                        key: 'action',
                        width: 100,
                        fixed: 'right'
                    }],
					13: [{
						title: '影院名称',
						dataIndex: 'cinemaName'
					}, {
						title: '影城充值卡等级',
						dataIndex: 'cardTitle'
					}, {
						title: '影城充值卡号',
						dataIndex: 'cardNumber'
					}, {
						title: '可用余额',
						dataIndex: 'balance'
					}, {
						title: '状态',
						key: 'isDisabled'
					}, {
						title: '操作',
						key: 'action',
						fixed: 'right',
						width: 100
					}],
					14: [
                      {
                        title: "所属影院",
                        dataIndex: "cinemaName",
                      },
                      {
                        title: "影城会员卡卡号",
                        dataIndex: "cardNumber",
                      },
                      {
                        title: "影城会员卡余额",
                        dataIndex: "balance",
                      },
                      {
                        title: "状态",
                        key: "isDisabled",
                      },
                      {
                        title: "操作",
                        key: "action",
                        fixed: "right",
                        width: 100,
                      },
                    ],
                }
                return colomn[index];
            },
            // 刷新
            onRefresh(){
                this.getData();
                this.getMemberDetail();
            },
            // 冻结
            onFrozen(){
                this.$confirm({
                    title: 'warning',
                    content: this.memberInfo.isDisabled == 1 ? '解冻的会员可正常登录账号' : '冻结的会员无法登录账号',
                    okType: 'danger',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: ()=> {
                        if(this.memberInfo.isDisabled == 1){
                            this.enabledUser();
                        }else{
                            this.visibleReason = true;
                        }
                    }
                })
            },
            onBind(){
                if(this.memberInfo.wxNo){
                    this.$confirm({
                        title: '确定解绑该卡绑定的微信号',
                        content: '解绑后需要重新绑定，请谨慎',
                        okType: 'danger',
                        okText: '确认',
                        cancelText: '取消',
                        onOk: ()=> {
                            this.$message.success('解绑成功');
                        }
                    })
                }else{
                    this.visibleBind = true;
                }
            },
			onBindCardOk() {
				this.$refs.bindForm.validateFields().then(async () => {
					let postData = this.$deepClone(this.bindModel);
					if (this.bindModel.type === 1) {
						if (postData.usableTotal > postData.cardBalance) {
							return this.$message.warn('次卡剩余次数不得大于开卡总次数');
						}
					}
					postData.userId = this.id;
					if (postData.isNeverExpire === 0) {
						postData.expireDate = postData.expireDate.endOf('day').unix();
					} else {
						delete postData.expireDate
					}
					delete postData.type;
					if (this.bindModel.type !== 3) {
						postData.createTime = postData.createTime.startOf('day').unix();
					}
					let ret;
					this.loading = true;
					try {
						if (this.bindModel.type === 1) {
							//  绑定次卡
							ret = await bindNumberCard(postData);
						} else if (this.bindModel.type === 2) {
							// 绑定年卡
							ret = await bindYearCard(postData);
						} else if (this.bindModel.type === 3) {
                            ret = await bindRechargeCard(postData);
                        } else if(this.bindModel.type === 4){
                            ret = await bindMemberCard(postData);
                        }
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('绑定成功！');
							this.$refs.bindForm.resetFields();
							this.bindCardVisible = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
            handleBindOk(){
                if(!this.wxNo){
                    this.$message.warn('请输入微信号');
                    return;
                }
                // 调接口
                return
                this.$message.success('绑定成功');
                this.visibleBind = false;
            },
            onBindCoupon(){
				this.getOrganizationList()
                this.visibleBindCoupon = true;
            },
            handleBindCouponOk(){
				this.$refs.cardFormRef.validateFields().then(() => {
					this.bindExchange();
				})
            },
            handleBindCouponCancel(){
				this.$refs.cardFormRef.resetFields();
                this.visibleBindCoupon = false;
                this.cardModel = {};
            },
            handleReasonOk(){
                if(!this.frozenReason){
                    this.$message.warn('请输入冻结原因');
                    return;
                }
                this.disabledUser();
            },
            onSee() {
				this.$success({
                    content: this.phone,
                    okText: '知道了',
                });
            },
            hiddenPhone(phone){
                let reg = /^(\d{3})\d{4}(\d{4})$/;
                return phone.toString().replace(reg, "$1****$2");
            },
            transSex(type){
                const sexObj = {
                    1: '男',
                    2: '女',
                    3: '未知'
                }
                return sexObj[type];
            },
            async getCardList(){
                const postData = {
                    page: this.pagination.current,
                    pageSize: this.pagination.pageSize,
                    userId: this.id,
                    type: this.currentTab == 2 ? 1 : this.currentTab == 13 ? 3 : this.currentTab == 14 ? 4 : 2,//1次卡 2年卡
                }
                this.loading = true;
                try {
                    let {data} = await getCardList(postData);
                    this.loading = false;
                    this.list = data.list;
                    this.pagination.total = data.totalCount;
                } catch (error) {
                    this.loading = false;
                }
            },
            async getCouponList(){
                const postData = {
                    page: this.pagination.current,
                    pageSize: this.pagination.pageSize,
                    userId: this.id,
                    couponType: this.currentTab == 1 ? 2 : this.currentTab == 10 ? 3 : 1,//1优惠券 2兑换券
                }
                this.loading = true;
                try {
                    let {data} = await getCouponList(postData);
                    this.loading = false;
                    this.list = data.list;
                    this.pagination.total = data.totalCount;
                } catch (error) {
                    this.loading = false;
                }
            },
            transCouponStatus(status){
                const couponStatus = {
                    1: '正常',
                    2: '已兑换',
                    3: '已过期',
                    4: '未激活'
                }
                return couponStatus[status];
            },
            transCardStatus(status){
                const cardStatus = {
                    1: '正常',
                    2: '过期',
                    3: '手动停用',
                    4: '信用停用',
                    5: '启用审核中',
                    6: '未认证',
                    7: '退卡',
                    8: '已回收'
                }
                return cardStatus[status];
            },
            async disabledUser(){
                let res = await disabledUser({
                    id: this.id,
                    reason: this.frozenReason,
                });
                if(res.code == 200){
                    this.getData();
                    this.getMemberDetail();
                    this.visibleReason = false;
                    this.$message.success('冻结成功');

                }
            },
            async enabledUser(){
                let res = await enabledUser({ id: this.id });
                if(res.code == 200){
                    this.getData();
                    this.getMemberDetail();
                    this.$message.success('解冻成功');
                }
            },
            async getOrderList(){
                const postData = {
                    page: this.pagination.current,
                    pageSize: this.pagination.pageSize,
                    userId: this.id,
                }
                if(this.currentTab == 5){
                    postData.saleType = 1;
                }
                if(this.currentTab == 4){
                    postData.saleType = 3;
                }
                if(this.currentTab == 8){
                    postData.saleType = 2;
                }
                this.loading = true;
                try {
                    let {data} = await getOrderList(postData);
                    this.loading = false;
                    data.list.forEach(item=>{
                        if(this.currentTab == 5 && item.goodsInfoVOS.length && item.goodsInfoVOS[0].imgUrl){
                            item.goodsInfoVOS[0].goodsImgUrl = item.goodsInfoVOS[0].imgUrl.split(',')[0];
                        }
                    });
                    this.list = data.list;
                    this.pagination.total = data.totalCount;
                } catch (error) {
                    this.loading = false;
                }
            },
            async getCommissionList(){
                const postData = {
                    page: this.pagination.current,
                    pageSize: this.pagination.pageSize,
                    userId: this.id,
                }
				if (this.currentTab == 6) {
					postData.saleType = 1;
				}
				if (this.currentTab == 12) {
					postData.saleType = 1;
				}
				if (this.currentTab == 11) {
					postData.saleType = 3;
				}
                // if(this.currentTab == 6){
                //     postData.level = 2;
                // }
                // if(this.currentTab == 7){
                //     postData.level = 1;
                // }
                this.loading = true;
                try {
                    let {data} = await getCommissionList(postData);
                    this.loading = false;
                    data.list.forEach(item=>{
                        if(item.oiList.length && item.oiList[0].picUrl){
                            item.oiList[0].goodsImgUrl = item.oiList[0].picUrl.split(',')[0];
                        }
                    })
                    this.list = data.list;
                    this.pagination.total = data.totalCount;
                } catch (error) {
                    this.loading = false;
                }
            },
			onBindCard(type) {
				this.organizationList = [];
				this.cinemaAllList = [];
				this.cardPolicyList = [];
				this.bindModel.type = type;
				if (type === 2) {
					this.bindModel.cardType = 1;
				}
				if (type === 3) {
					this.bindModel.isDisabled = 0;
				}
				if(type===4){
                  this.bindModel.phone = this.memberInfo.phone
                  delete this.bindModel.isNeverExpire
                }else {
					this.bindModel.isNeverExpire = 1;
					this.bindModel.expireDate = undefined;
				}
				this.getOrganizationList();
				this.bindCardVisible = true;
			},
			onLabelClose(item) {
				this.$confirm({
					title: '提示',
					content: '确定取消该用户标签吗？',
					onOk: async ()=> {
						this.loading = true;
						let data = [...this.labelIds];
						let index = data.indexOf(item.tagId);
						data.splice(index, 1);
						try {
							let ret = await updateUserTag({
								userId: this.id,
								tagIds: data.join(',')
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('取消用户标签成功');
								this.getMemberDetail();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
				return false;
			},
			async onLabelChange(data) {
				this.loading = true;
				try {
					let ret = await updateUserTag({
						userId: this.id,
						tagIds: this.labelIds.join(',')
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('打标签成功');
						this.getMemberDetail();
					}
				} catch(e) {
					this.loading = false;
				}
			},
            onCopy(index) {
				const range = document.createRange();
				range.selectNode(document.querySelector("#copys" + index));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
			},
            transOrderStatus(status){
                const statusObj = {
                    'SYSTEM_CANCEL': '系统超时取消',
                    'CUSTOMER_CANCEL': '用户自行取消',
                    'WAIT_BUYER_PAY': '待付款',
                    'WAIT_SELLER_SEND_GOODS': '待发货',
                    'WAIT_CHECK': '待核销',
                    'WAIT_BUYER_CONFIRM_GOODS': '待收货',
                    'CHECKOUT_SUCCESS': '已付款',
                    'TRADE_FINISH': '订单完成',
                    'AFTERSALE_FINISH': '售后完成'
                }
                return statusObj[status];
            },
            transRefundTicketStatus(status){
                const statusObj = {
                    'SYSTEM_CANCEL': '系统超时取消',
                    'CUSTOMER_CANCEL': '用户自行取消',
                    'WAIT_BUYER_PAY': '待付款',
                    'CHECKOUT_SUCCESS': '待取票',
                    'TRADE_FINISH': '订单完成',
                    'AFTERSALE_FINISH': '售后完成'
                }
                return statusObj[status];
            },
            transTicketStatus(status){
                const statusObj = {
                    1: '未取票',
                    2: '取票中',
                    3: '已取票',
                }
                return statusObj[status];
            },
            onDelay(item) {
				if (this.currentTab == 1 || this.currentTab == 9 || this.currentTab == 10) {
					if (this.currentTab == 1 || this.currentTab == 9) {
						this.currentDelayTitle = '影城兑换券延期';
					}
					if (this.currentTab == 10) {
						this.currentDelayTitle =  '卖品券延期';
					}
					item.cardNumber = item.barcode;
					item.expireDate = item.endTime;
				}
				if (this.currentTab == 2) {
					this.currentDelayTitle = '次卡延期';
				}
				if (this.currentTab == 3) {
					this.currentDelayTitle = '年卡延期';
				}
				this.setRecordData = item;
				this.delayVisible = !this.delayVisible;
			},
            onDelayCancel() {
				this.delayModelRef = {};
				this.delayVisible = false;
			},
            onDelayOk() {
				// 延期
				this.$refs.delayForm.validateFields().then(async () => {
					if (!this.visible) {
						this.loading = true;
						let ret;
						if(this.currentTab == 1 || this.currentTab == 9 || this.currentTab == 10){
							ret = await delayExchange({
								id: this.setRecordData.id,
								delayTime: Math.floor(this.delayEndTime(this.setRecordData.expireDate) / 1000)
							})
						} else {
							ret = await delayCardInfo({
								id: this.setRecordData.id,
								delayTime: Math.floor(this.delayEndTime(this.setRecordData.expireDate) / 1000)
							});
						}
						this.loading = false;
						if (ret.code === 200) {
							this.getData();
							this.delayModelRef = {};
							this.setRecordData = {};
							this.$message.success('操作成功');
							this.delayVisible = false;
						}
					} else {
						this.delayVisible = false;
					}
				})
			},
            delayEndTime(expireDate) {
				//  延期时间处理
				if(!expireDate) return '';
				if ((expireDate+'').length === 10) {
					expireDate = expireDate * 1000;
				}
				if (this.delayModelRef.setValue === 1) {
					return this.moment(expireDate).add(1, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 2) {
					return this.moment(expireDate).add(7, 'day').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 3) {
					return this.moment(expireDate).add(3, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 4) {
					return this.moment(expireDate).add(6, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 5) {
					return this.moment(expireDate).add(12, 'month').endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 6) {
					return this.delayModelRef.specifiesTime.endOf('day').valueOf();
				} else if (this.delayModelRef.setValue === 7) {
					if (this.delayModelRef.delayDays) {
						return this.moment(expireDate).add(this.delayModelRef.delayDays, 'day').endOf('day').valueOf();
					} else {
						return this.moment(expireDate).endOf('day').valueOf();
					}
				} else {
					return this.moment(expireDate).endOf('day').valueOf();
				}
			},
            onRefund(item) {
				this.refundData = {
					id: item.id
				};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.refundData = {};
				this.refundVisible = false;
			},
            onRefundOk() {
				this.$refs.refundForm.validateFields().then(async ()=> {
					this.loading = true;
					let ret = await cancelCard({
						id: this.refundData.id,
						refundCardRemark: this.refundData.remark
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('退卡成功');
						this.onRefundCancel();
						this.getData();
					}
				})
			},
            onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: "确定" + (item.isDisabled === 0 ? '禁用' : '启用') + '此卡吗？',
					onOk:async () => {
						this.loading = true;
						let ret;
						try {
                            if (item.isDisabled === 0) {
                                ret = await pullOffShelvesCardInfo({
                                    id: item.id
                                })
                            } else {
                                ret = await pullOnShelvesCardInfo({
                                    id: item.id
                                })
                            }
                            this.loading = false;
                            if (ret.code === 200) {
                                this.$message.success('操作成功');
                                this.getData();
                            }
                        } catch (error) {
                            this.loading = false;
                        }
					}
				})
			},
			onModifyBalance(record) {
				this.modifyItem = record;
				this.modifyModelRef = {};
				this.showModifyModal = true;
			},
			onMemberCardDetails(record) {
              this.memberCardInfo = record;
              this.showRecord = true;
            },
			onModifyCancel() {
				this.$refs.modifyForm.resetFields();
				this.modifyItem = {};
				this.showModifyModal = false;
			},
			onModifyOk() {
				this.$refs.modifyForm.validateFields().then(async ()=> {
					this.loading = true;
					try {
						let ret = await modifyBalance({
							id: this.modifyItem.id,
							balance: this.modifyModelRef.balance
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('余额调整成功');
							this.$refs.modifyForm.resetFields();
							this.showModifyModal = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
            onUnBind(item) {
				this.$confirm({
					title: '提示',
					content:'确定解绑吗？',
					onOk: async () => {
						this.loading = true;
						let ret = await unbundlingExchangeInfo({
							id: item.id
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.getData();
						}
					}
				})
			},
            async bindExchange(){
                this.loading = true;
                try {
                    let res = await bindExchange({
                        userId: this.id,
                        barcode: this.cardModel.couponNo,
						cinemaId: this.cardModel.cinemaId
                    })
                    this.loading = false;
                    if(res.code == 200){
                        this.getData();
						this.$refs.cardFormRef.resetFields();
                        this.$message.success('绑定成功');
                        this.visibleBindCoupon = false;
                        this.cardModel = {};
                    }
                } catch (error) {
                    this.loading = false;
                }
            },
            disabledDate(current) {
                return current && current < this.moment().endOf('day');
            },
			disabledCreateTime(current) {
                return current && current > this.moment().endOf('day');
            },
        }
	}
</script>

<style scoped>
.ui-copy__btn {
  color: #6699ff;
  cursor: pointer;
  outline: none;
  background-color: #ffffff;
  border: none;
}

.ui-price {
  color: orange;
  font-size: 18px;
}

.ui-goods {
  padding: 6px 10px;
  border-radius: 10px;
  border: solid 1px #eee;
}

.ui-goods + .ui-goods {
  margin-top: 4px;
}

.ui-title {
  margin: 20px 0 10px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.ui-title::before {
  content: "";
  width: 4px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1890ff;
  border-radius: 2px;
}

.ui-card {
  padding: 20px;
  margin-bottom: 30px;
  background-color: #eee;
  border-radius: 10px;
}

.ui-label {
  height: 28px;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 28px;
}
</style>
